<template>
  <MasterTable
    title="Fordonstyper"
    :rows="vehicleTypes"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="name"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form>
      <div class="row" style="min-width: 300px">
        <div class="col-12 q-pa-md">
          <q-select
            dense
            emit-value
            map-options
            option-value="uuid"
            option-label="name"
            :loading="loadingStateVehicleTypeGroups.getAll"
            v-model="model.vehicleTypeGroupUuid"
            :options="vehicleTypeGroups"
            label="Fordonstyp grupp"
            :rules="[requiredRule]"
          />
        </div>
        <div class="col-12 q-pa-md">
          <q-input
            dense
            v-model="model.name"
            label="Fordonstyp"
            :rules="[requiredRule]"
          />
        </div>
        <div class="col-3 q-pa-md">
          <q-input
            dense
            v-model.number="model.customerAvailabilityLevel1"
            label="Kundtillgänglighet nivå 1"
            :rules="[requiredNotNegative]"
            type="number"
          />
        </div>
        <div class="col-3 q-pa-md">
          <q-input
            dense
            v-model.number="model.customerAvailabilityLevel2"
            label="Kundtillgänglighet nivå 2"
            :rules="[requiredNotNegative]"
            type="number"
          />
        </div>
        <div class="col-3 q-pa-md">
          <q-input
            dense
            v-model.number="model.customerAvailabilityLevel3"
            label="Kundtillgänglighet nivå 3"
            :rules="[requiredNotNegative]"
            type="number"
          />
        </div>
        <div class="col-3 q-pa-md">
          <q-input
            dense
            v-model.number="model.customerAvailabilityLevel4"
            label="Kundtillgänglighet nivå 4"
            :rules="[requiredNotNegative]"
            type="number"
          />
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useVehicleType } from '@/composable/useVehicleType'
import { VehicleType } from '@/types/vehicle-type'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { useVehicleTypeGroup } from '@/composable/useVehicleTypeGroup'

export default defineComponent({
  name: 'MasterVehicleTypes',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateVehicleTypes,
      data: vehicleTypes,
      add,
      update,
      remove,
    } = useVehicleType(true)

    const {
      fetchAll: getAllVehicleTypeGroups,
      loading: loadingStateVehicleTypeGroups,
      data: vehicleTypeGroups,
    } = useVehicleTypeGroup()

    getAllVehicleTypeGroups()

    function createFormModel(): VehicleType {
      return {
        uuid: uuidv4(),
        name: '',
        vehicleTypeGroupUuid: '',
        customerAvailabilityLevel1: 0,
        customerAvailabilityLevel2: 0,
        customerAvailabilityLevel3: 0,
        customerAvailabilityLevel4: 0,
      }
    }
    const model = ref<VehicleType>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Fordonstyp',
        align: 'left',
        field: 'name',
        sortable: true,
      },
      {
        name: 'vehicleTypeGroup',
        label: 'Fordonstyp grupp',
        align: 'left',
        field: (v: VehicleType) => v?.vehicleTypeGroup?.name,
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel1',
        label: 'Kundtillgänglighet nivå 1',
        align: 'left',
        field: 'customerAvailabilityLevel1',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel2',
        label: 'Kundtillgänglighet nivå 2',
        align: 'left',
        field: 'customerAvailabilityLevel2',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel3',
        label: 'Kundtillgänglighet nivå 3',
        align: 'left',
        field: 'customerAvailabilityLevel3',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel4',
        label: 'Kundtillgänglighet nivå 4',
        align: 'left',
        field: 'customerAvailabilityLevel4',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateVehicleTypes.value,
        getAll: loadingStateVehicleTypes.value.getAll,
      }
    })

    const exportData = computed(() => {
      return vehicleTypes.value.map((vehicleType) => {
        return {
          Fordonstyp: vehicleType.name,
          'Kundtillgänglighet nivå 1': vehicleType.customerAvailabilityLevel1,
          'Kundtillgänglighet nivå 2': vehicleType.customerAvailabilityLevel2,
          'Kundtillgänglighet nivå 3': vehicleType.customerAvailabilityLevel3,
          'Kundtillgänglighet nivå 4': vehicleType.customerAvailabilityLevel4,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      loadingStateVehicleTypeGroups,
      vehicleTypeGroups,
      vehicleTypes,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
      requiredNotNegative,
    }
  },
})
</script>
